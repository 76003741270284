import React, { ReactNode } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Reset } from 'styled-reset'

import Media from '../../utils/Media'
import Footer from './Footer'
import Header from './Header'

interface Props {
  children: ReactNode
}

const Layout: React.FC<Props> = ({ children }: Props) => (
  <div>
    <Reset />
    <GlobalStyle />
    <Header />
    <Main>{children}</Main>
    <Footer />
  </div>
)

const Main = styled.div`
  padding-top: 80px;

  ${Media.mobileTablet} {
    padding-top: 80px;
  }
`

const GlobalStyle = createGlobalStyle`
  html {
    font-family: var(--font-noto-sans-jp), sans-serif;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
  }
  * {
    box-sizing: border-box;
  }
`

export default Layout
