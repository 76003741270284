// import Image from 'next/image'
import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import ButtonRounded from '../../shared/ButtonRounded'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'
import Grid from '../../shared/Grid'
import Heading from '../../shared/Heading'
import ImagesSlider from '../../shared/ImagesSlider'
import Stack from '../../shared/Stack'

export const SolutionSection: React.FC = () => (
  <StyledDiv>
    <Container size="large">
      <SectionHead>
        <Stack gap="10">
          <Heading
            label="FOR BUSINESS"
            size="20"
            color="blue"
            $mobileSize="14"
          />
          <DualLangHeading en="SOLUTION" ja="RYDE PASSで実現できること" />
        </Stack>
      </SectionHead>
      <GridWrap>
        <Grid by={3} $tablet={1} $mobile={1}>
          <div>
            <ItemHead>
              <ItemIndex>
                <span>1</span>
              </ItemIndex>
              <ItemTitle>
                <span>簡単にデジタルチケットが発券可能に。</span>
              </ItemTitle>
            </ItemHead>
            <ItemThumb>
              <Image
                src="/images/picture/top_solution_01.jpg"
                fill
                style={{ objectFit: 'cover' }}
                alt=""
                sizes="33vw"
              />
            </ItemThumb>
            <ItemDescription>
              <p>
                フリーパス、観光施設と連携した往復券、定期券など、
                交通分野の様々な乗車券・乗船券等々を、わかりやすく・使いやすいデジタルサービスとして簡単に実現できます。
              </p>
            </ItemDescription>
          </div>
          <div>
            <ItemHead>
              <ItemIndex>
                <span>2</span>
              </ItemIndex>
              <ItemTitle>
                <span>見えてこなかった自社交通の今が見えるように。</span>
              </ItemTitle>
            </ItemHead>
            <ItemThumb>
              <Image
                src="/images/picture/top_solution_02.jpg"
                fill
                style={{ objectFit: 'cover' }}
                alt=""
                sizes="33vw"
              />
            </ItemThumb>
            <ItemDescription>
              <p>
                デジタルチケットの発券や移動に関するデータの見える化は勿論のこと、データを用いた新たな仮説検証やマーケティング施策を、誰でも簡単に実践できます。
              </p>
            </ItemDescription>
          </div>
          <div>
            <ItemHead>
              <ItemIndex>
                <span>3</span>
              </ItemIndex>
              <ItemTitle>
                <span>重い費用負担なしで自社交通のデジタル化が可能に。</span>
              </ItemTitle>
            </ItemHead>
            <ItemThumb>
              <Image
                src="/images/picture/top_solution_03.jpg"
                fill
                style={{ objectFit: 'cover' }}
                alt=""
                sizes="33vw"
              />
            </ItemThumb>
            <ItemDescription>
              <p>
                RYDE
                PASSを使えば自社交通のデジタル化に伴う開発費用はなし。更に保守費用もかからず、重い費用負担なしで自社交通のデジタル化が簡単に可能になります。
              </p>
            </ItemDescription>
          </div>
        </Grid>
      </GridWrap>
      <ImagesSlider />
      <BtnAera>
        <ButtonRounded
          href="https://form.run/@ryde-pass-contact"
          color="blue"
          height="72"
          icon="arrow"
        >
          RYDE PASSを導入する
        </ButtonRounded>
      </BtnAera>
    </Container>
  </StyledDiv>
)

const StyledDiv = styled.div`
  position: relative;
  margin-top: 123px;

  ${Media.mobileMiddle} {
    margin-top: 80px;
  }
`
const SectionHead = styled.div`
  margin-bottom: 54px;

  ${Media.mobileMiddle} {
    margin-bottom: 44px;
  }
`

const ItemHead = styled.div`
  display: flex;
`

const ItemIndex = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  ${theme.typography.FontOverpassBlack}
  width: 60px;
  color: #1e9af3;
  font-size: 48px;
  span {
    display: block;
    transform: translateY(0.1em);
  }
`

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  ${theme.typography.FontNotoBold}
  font-size: 18px;
  line-height: 1.66;
`

const ItemThumb = styled.div`
  position: relative;
  aspect-ratio: 320 / 240;
  margin-top: 22px;
  overflow: hidden;
  border-radius: 16px 16px;
`

const ItemDescription = styled.div`
  margin-top: 12px;
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 2;
`

const GridWrap = styled.div`
  margin-bottom: 69px;
  ${Media.mobileMiddle} {
    margin-bottom: 40px;
    & > div > div + div {
      margin-top: 50px;
    }
  }
`

const BtnAera = styled.div`
  width: 496px;
  margin: 70px auto 0 auto;
  a {
    font-size: 20px;
  }

  ${Media.mobileMiddle} {
    width: 100%;
    a {
      font-size: 16px;
      line-height: 1.45;
      text-align: center;
    }
  }
`
