import Image from 'next/image'
import React from 'react'
import styled, { css } from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'
import { useMatchMedia } from '../utils/useMatchMedia'
import AppDownloadLinks from './AppDownloadLinks'
import { ConditionalBR } from './ConditionalBR'
import { Container } from './Container'

const AppDownloadSection: React.FC<{ $noMarginTop?: boolean }> = ({
  $noMarginTop = false,
}) => {
  const isMobileSize = useMatchMedia('(max-width: 800px)')
  return (
    <Wrap $noMarginTop={$noMarginTop}>
      <Container>
        <Grid>
          <Bg>
            <Image
              src="/images/common/AppDownloadSectionBg.jpg"
              fill
              style={{ objectFit: 'cover' }}
              alt=""
              priority={false}
            />
          </Bg>
          <ImgSection className="Img">
            <Image
              src="/images/common/iphone.png"
              width="205"
              height="403"
              alt=""
            />
          </ImgSection>
          <TopSection className="Top">
            <Title>
              RYDE <ConditionalBR $tablet={true} $desktop={false} />
              PASS App
            </Title>
            <Description>
              RYDE PASSアプリのダウンロードはこちらから。
            </Description>
          </TopSection>
          <BottomSection className="Bottom">
            <AppDownloadLinks size={isMobileSize ? 'small' : 'medium'} />
            <Notice>
              App StoreはApple Inc.のサービスマークです。Android, Google
              Playは、Google Inc.
              の商標です。iOS商標は、米国Ciscoのライセンスに基づき使用されています。
            </Notice>
          </BottomSection>
        </Grid>
      </Container>
    </Wrap>
  )
}
export default AppDownloadSection

const Wrap = styled.div<{ $noMarginTop: boolean }>`
  position: relative;
  z-index: 2;
  height: auto;
  margin-top: 130px;

  /* stylelint-disable */
  ${(props) =>
    props.$noMarginTop &&
    css`
      margin-top: -238px;
    `}
  /* stylelint-enable */
  ${Media.mobileMiddle} {
    ${(props) =>
      props.$noMarginTop &&
      css`
        margin-top: -200px;
      `}
  }
`

const ImgSection = styled.div`
  position: relative;
  grid-area: imgSection;
  padding-left: 16%;

  img {
    transform: translateY(-57px);
  }

  ${Media.mobileMiddle} {
    padding-left: 0;
    img {
      width: 100%;
      height: auto;
      transform: translateY(0);
    }
  }
`

const TopSection = styled.div`
  display: flex;
  position: relative;
  grid-area: topSection;
  flex-direction: column;
  justify-content: flex-end;

  ${Media.mobileMiddle} {
    padding-left: 20px;
  }
`

const BottomSection = styled.div`
  position: relative;
  grid-area: bottomSection;
  padding-right: 25%;

  ${Media.tablet} {
    padding-right: 20%;
  }

  ${Media.mobileMiddle} {
    margin-top: 28px;
    padding-right: 0;
  }
`

const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-areas:
    'imgSection topSection'
    'imgSection bottomSection';
  grid-template-columns: 40% 60%;
  grid-template-rows: 50% 50%;

  ${Media.mobileMiddle} {
    grid-template-areas:
      'imgSection topSection'
      'bottomSection bottomSection';
    grid-template-columns: 20% 80%;
    grid-template-rows: auto auto;
    padding-top: 27px;
    padding-bottom: 14px;
    padding-right: 20px;
  }
`

const Bg = styled.div`
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  width: calc(var(--vw) * 50 + 50%);
  height: 100%;
  overflow: hidden;
  border-radius: 0 16px 16px 0;
  background-color: #f5f5f5;
`

const Title = styled.p`
  ${theme.typography.FontOverpassBlack}
  font-size: 48px;
  letter-spacing: 0.05em;

  ${Media.mobileMiddle} {
    font-size: 36px;
    line-height: 1.2;
  }
`

const Description = styled.p`
  ${theme.typography.FontNotoMedium}
  margin-top: 8px;
  margin-bottom: 28px;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.5;

  ${Media.mobileMiddle} {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 1.4;
  }
`

const Notice = styled.p`
  margin-top: 26px;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 2;

  ${Media.mobileMiddle} {
    margin-top: 21px;
    line-height: 1.9;
  }
`
