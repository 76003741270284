import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
  gap?: '10' | '20' | '30' | '40' | '50'
}

const Stack: React.FC<Props> = ({ children, gap = '20' }) => (
  <Wrapper gap={gap}>{children}</Wrapper>
)

export default Stack

interface WrapProps {
  gap: '10' | '20' | '30' | '40' | '50'
}

const Wrapper = styled.div<WrapProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: ${(props) => `${props.gap}px`};
`
