import NextLink from 'next/link'
import React from 'react'
import styled from 'styled-components'

import LogoImg from '../../assets/svgs/RYDE.svg'
import Media from '../../utils/Media'
import hoverOpacity from '../utils/hoverOpacity'

interface LogoProps {
  color?: 'white' | 'black'
  onFocus?: () => void
  onBlur?: () => void
}

const Logo: React.FC<LogoProps> = ({
  color = 'black',
  onFocus = () => {},
  onBlur = () => {},
}) => (
  <Wrapper
    href="/"
    aria-label="RYDE Inc."
    color={color}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    <LogoImg />
  </Wrapper>
)

export default Logo

const Wrapper = styled(NextLink)<LogoProps>`
  display: flex;
  z-index: 10;
  align-items: center;
  color: ${(props) => (props.color === 'white' ? '#FFF' : '#000')};
  text-decoration: none;
  gap: 16px;

  svg {
    width: 83px;
    height: 23px;
    path {
      fill: ${(props) => (props.color === 'white' ? '#FFF' : '#000')};
    }
  }

  ${Media.desktop} {
    svg {
      width: 83px;
      height: 23px;
    }

    ${hoverOpacity()}
  }
`
