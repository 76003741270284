import { css } from 'styled-components'

import Media from '../utils/Media'
import colors from './colors'

const FontNotoRegular = css`
  font-family: var(--font-noto-sans-jp), sans-serif;
  font-weight: 400;
`
const FontNotoMedium = css`
  font-family: var(--font-noto-sans-jp), sans-serif;
  font-weight: 500;
`
const FontNotoBold = css`
  font-family: var(--font-noto-sans-jp), sans-serif;
  font-weight: 700;
`

const FontOverpassBold = css`
  font-family: var(--font-overpass), sans-serif;
  font-weight: 700;
  letter-spacing: -0.015em;
`
const FontOverpassBlack = css`
  font-family: var(--font-overpass), sans-serif;
  font-weight: 900;
  letter-spacing: -0.015em;
`
const FontShueiMincho = css`
  font-family: dnp-shuei-mincho-pr6n, sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
`

const DefShueiMincho2 = css`
  font-family: dnp-shuei-mincho-pr6n, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  letter-spacing: 0.1em;
`

const DefShueiMincho3 = css`
  font-family: dnp-shuei-mincho-pr6n, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 2.083;
  letter-spacing: 0.1em;
`

const DefNotoSans3 = css`
  font-family: var(--font-noto-sans-jp), sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.5;
`

const DefNotoSans2 = css`
  font-family: var(--font-noto-sans-jp), sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.5;
`

const DefNotoSans4 = css`
  font-family: var(--font-noto-sans-jp), sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.75;
`

const DefNotoSansBody = css`
  font-family: var(--font-noto-sans-jp), sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
`

const DefNotoSansBodyBold = css`
  font-family: var(--font-noto-sans-jp), sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
`

const Hero = css`
  color: ${colors.textBlackPrimary};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;

  ${Media.desktop} {
    font-size: 40px;
  }
`

const HeroSub = css`
  color: ${colors.textBlackPrimary};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;

  ${Media.desktop} {
    font-size: 24px;
  }
`

const Headline1 = css`
  color: ${colors.textBlackPrimary};
  font-weight: bold;
  line-height: 1.25;

  ${Media.desktop} {
    font-size: 30px;
  }

  ${Media.mobileTablet} {
    font-size: 20px;
  }
`
const Headline2 = css`
  color: ${colors.textBlackPrimary};
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;

  ${Media.desktop} {
    font-size: 32px;
  }
`

const Headline3 = css`
  color: ${colors.textBlackPrimary};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.25;

  ${Media.desktop} {
    font-size: 24px;
  }
`

const Headline4 = css`
  color: ${colors.textBlackPrimary};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;

  ${Media.desktop} {
    font-size: 20px;
  }
`

const Headline5 = css`
  color: ${colors.textBlackPrimary};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;

  ${Media.desktop} {
    font-size: 18px;
  }
`

const Headline6 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;

  ${Media.desktop} {
    font-size: 16px;
  }
`

const Headline7 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;
`

const Subtitle1 = css`
  color: ${colors.textBlackPrimary};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;

  ${Media.desktop} {
    font-size: 20px;
  }
`

const Subtitle2 = css`
  color: ${colors.textBlackPrimary};
  font-size: 12px;
  line-height: 1.5;

  ${Media.desktop} {
    font-size: 14px;
  }
`

const Body1 = css`
  color: ${colors.textBlackPrimary};
  font-size: 16px;
  line-height: 1.75;

  ${Media.desktop} {
    font-size: 20px;
  }
`

const Body3 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  line-height: 1.75;

  ${Media.desktop} {
    font-size: 16px;
  }
`

const Body4 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  line-height: 1.75;
`

const Button1 = css`
  color: ${colors.textBlackPrimary};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-decoration: none;
`

const Button2 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  line-height: 1.25;

  ${Media.desktop} {
    font-size: 16px;
  }
`

const Button3 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;
`

const Button4 = css`
  color: ${colors.textBlackPrimary};
  font-size: 10px;
  line-height: 1.25;
`

const Accessory1 = css`
  font-size: 16px;

  ${Media.desktop} {
    font-size: 18px;
  }
`

const Accessory2 = css`
  font-size: 10px;
`

const Overline1 = css`
  font-size: 10px;
`

export default {
  FontNotoRegular,
  FontNotoMedium,
  FontNotoBold,
  FontOverpassBold,
  FontOverpassBlack,
  FontShueiMincho,
  DefShueiMincho2,
  DefShueiMincho3,
  DefNotoSans2,
  DefNotoSans3,
  DefNotoSans4,
  DefNotoSansBody,
  DefNotoSansBodyBold,
  Hero,
  HeroSub,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Headline6,
  Headline7,
  Subtitle1,
  Subtitle2,
  Button1,
  Button2,
  Button3,
  Button4,
  Body1,
  Body3,
  Body4,
  Accessory1,
  Accessory2,
  Overline1,
}
