import styled, { css } from 'styled-components'

import Media from '../../utils/Media'

const gutter = 20

const Grid = styled.div<{
  by: number
  $mobile?: number
  $tablet?: number
  $desktop?: number
}>`
  display: flex;
  flex-wrap: wrap;

  margin-right: ${`-${gutter}px`};
  margin-left: ${`-${gutter}px`};

  & > * {
    box-sizing: border-box;
    width: calc(100% / ${(props) => props.by});
    padding-right: ${`${gutter}px`};
    padding-left: ${`${gutter}px`};

    ${(props) =>
      props.$mobile &&
      css`
        ${Media.mobile} {
          width: calc(100% / ${props.$mobile});
        }
      `}

    ${(props) =>
      props.$tablet &&
      css`
        ${Media.tablet} {
          width: calc(100% / ${props.$tablet});
        }
      `}

    ${(props) =>
      props.$desktop &&
      css`
        ${Media.desktop} {
          width: calc(100% / ${props.$desktop});
        }
      `}
  }
`

export default Grid
