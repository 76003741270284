import React from 'react'
import styled, { css } from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

interface Props {
  label?: string
  size?: '16' | '20' | '24'
  color?: 'white' | 'black' | 'blue'
  $mobileSize?: '14' | '16' | undefined
}

const Heading: React.FC<Props> = ({
  label = 'Heading',
  size = '24',
  color = 'white',
  $mobileSize = undefined,
}) => (
  <Wrap size={size} color={color} $mobileSize={$mobileSize}>
    {label}
  </Wrap>
)

export default Heading

const Wrap = styled.h2<Props>`
  ${theme.typography.FontOverpassBlack}
  color: ${(props) => getColorProps(props.color ? props.color : 'black').color};
  font-size: ${(props) => `${props.size}px`};
  letter-spacing: 0.1em;

  ${(props) =>
    props.$mobileSize &&
    css`
      ${Media.mobile} {
        font-size: ${`${props.$mobileSize}px`};
      }
    `}
`
const getColorProps = (color: string) => {
  switch (color) {
    case 'white':
      return {
        color: '#fff',
      }
    case 'black':
      return {
        color: '#000',
      }
    case 'blue':
      return {
        color: '#1e9af3',
      }
    default:
      return {
        color: '#000',
      }
  }
}
